import { render, staticRenderFns } from "./HQSecondarySideBar.vue?vue&type=template&id=33f6c33a&scoped=true&"
import script from "./HQSecondarySideBar.vue?vue&type=script&lang=ts&"
export * from "./HQSecondarySideBar.vue?vue&type=script&lang=ts&"
import style0 from "./HQSecondarySideBar.vue?vue&type=style&index=0&id=33f6c33a&lang=scss&scoped=true&"
import style1 from "./HQSecondarySideBar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f6c33a",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/staffs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc286406115%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2Fhq%2FHQSecondarySideBar.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "~/i18n/hq.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc286406115%2Fsrc%2Fthreease_pro%2Fcomponents%2Ffeatures%2Fhq%2FHQSecondarySideBar.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VCard,VIcon,VList,VListItem,VListItemTitle})

import { render, staticRenderFns } from "./SideBarItem.vue?vue&type=template&id=b97e3c4c&scoped=true&"
import script from "./SideBarItem.vue?vue&type=script&lang=ts&"
export * from "./SideBarItem.vue?vue&type=script&lang=ts&"
import style0 from "./SideBarItem.vue?vue&type=style&index=0&id=b97e3c4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b97e3c4c",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/sidebar.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc286406115%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FSideBar%2FSideBarItem.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemContent,VListItemTitle})

import { render, staticRenderFns } from "./TheGuideOverlay.vue?vue&type=template&id=7d65ec24&scoped=true&"
import script from "./TheGuideOverlay.vue?vue&type=script&lang=ts&"
export * from "./TheGuideOverlay.vue?vue&type=script&lang=ts&"
import style0 from "./TheGuideOverlay.vue?vue&type=style&index=0&id=7d65ec24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d65ec24",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc286406115%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FTheGuideOverlay.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDialog,VIcon})

import { render, staticRenderFns } from "./error.vue?vue&type=template&id=03b11f55&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=03b11f55&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b11f55",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/error.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc286406115%2Fsrc%2Fthreease_pro%2Flayouts%2Ferror.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageNotFound: require('/codebuild/output/src286406115/src/threease_pro/components/shared/Base/error/PageNotFound.vue').default,NonPermitted: require('/codebuild/output/src286406115/src/threease_pro/components/shared/Base/error/NonPermitted.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VContainer,VLayout,VMain})
